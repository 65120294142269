import React, { useMemo, useState } from "react";
import { Link } from "gatsby";

import { useCurrency } from "context/currencyContext";
import { useCart } from "context/cartContext";
import { useCategories } from "context/categoriesContext";
import { useBrands } from "context/brandsContext";

import Image from "components/UI/image";
import Button from "components/UI/button";

import { GTMAddToCartEvent } from "components/GTM/gtmProduct";

import "styles/product-card.scss";

const ProductCard = (props) => {
    const {
        product,
        productSelectClick,
        isHomepage
    } = props;

    const activeIngredient = product.custom_fields.find(cf => cf.name === "Substance")?.value;
    // const shortDescription = product.custom_fields.find(cf => cf.name === "Short_description")?.value;

    const isPrescription = product.custom_fields.find(cf => cf.name === "Prescription")?.value === 'true';
    const isQuestionnaire = product.custom_fields.find(cf => cf.name === "Questionnaire")?.value === 'true';
    const isPrescriptionOrQuestionnaire = isPrescription || isQuestionnaire;

    const isOneTimePurchase = product.custom_fields.find(cf => cf.name === "One-time-purchase")?.value === 'true';

    const url = product.custom_url?.url ?? '/';
    const imageSrc = product.variants[0].image_url || product.images[0]?.url_standard;

    const questionnaireLink = useMemo(() => {
        let link = "/questionnaire-start-page";
        if (product.categories.includes(24)) {
            link = "/questionnaire-hair";
        } else if (product.categories.includes(33)) {
            link = "/questionnaire-premature-ejaculation";
        } else if (product.categories.includes(32)) {
            link = "/questionnaire-erectile-dysfunction";
        }

        return link;
    }, [product.categories])

    const imagePlaceholderUrl =
        "//images.ctfassets.net/jns6jkghkn7l/56R83TDxML5MoVbA7hAVlE/ded876b66bb4b2ead1c743d60c59e658/Hims-Sildenafil-PDP-D-ATF-Image_3.png";

    const { increaseQty } = useCart();
    const { currency } = useCurrency();
    const { getCategoryName } = useCategories();
    const { getBrandName } = useBrands();

    const [isAdding, setIsAdding] = useState(false);

    return (
        <div className={`product-card${isHomepage ? ' product-card--homepage' : ''}`}>
            <div className="product-card__heading-container">
                <div className="product-card__heading">
                    <p className="product-card__heading-title">
                        {product.name}
                    </p>
                    {isPrescriptionOrQuestionnaire ? (
                        <div className="product-card__price-label product-card__price-label-RX typography__sub-text">
                            Rx
                        </div>
                    ) : (
                        <div className="product-card__price-label typography__sub-text">
                            {currency.token}{product.price}
                        </div>
                    )}
                </div>
                <p className="product-card__heading-subtitle">
                    {activeIngredient}
                </p>
            </div>
            <div className="product-card__image-container">
                <Image
                    className="product-card__image"
                    src={imageSrc || imagePlaceholderUrl}
                    alt={product.name}
                />
            </div>
            <div className="product-card__buttons">
                {isPrescriptionOrQuestionnaire ? (
                    <Button value="Start nu" type="dark" href={questionnaireLink}/>
                ) : (
                    isOneTimePurchase ? (
                        <Button
                            value="Toevoegen" type="dark"
                            onClick={() => {
                                setIsAdding(true);
                                const variant = product.variants
                                    .find(({ option_values }) => option_values
                                        .find(({ option_display_name }) => option_display_name === "Subscription")
                                        .label === "eenmalige aankoop" //one-time-purchase variant
                                    ) ?? product.variants[0];

                                increaseQty({
                                    ...product,
                                    variant_id: variant.id,
                                    quantity: 0
                                }, 1)
                                    .then(() => setIsAdding(false));

                                const quantity = (+variant.quantity || 1);
                                const price = variant.price ?? product.price;
                                GTMAddToCartEvent({
                                    currency: currency.currency_code,
                                    value: price,
                                    items: [{
                                        item_id: product.bigcommerce_id,
                                        item_name: product.name,
                                        item_brand: getBrandName(product.brand_id),
                                        item_category: getCategoryName(product.categories[0]),
                                        quantity,
                                        discount: product.price - price,
                                        item_variant: variant.id,
                                        price: price,
                                    }]
                                });
                            }}
                            disabled={isAdding}
                            isLoadingClass={isAdding}
                        />
                    ) : (
                        <Button value="Toevoegen" type="dark" href={url}/>
                    )
                )}
                <Link onClick={() => productSelectClick && productSelectClick(product.bigcommerce_id)}
                      to={url} className="product-card__buttons-link">
                    Lees meer
                </Link>
            </div>
        </div>
    );
};

export default ProductCard;
