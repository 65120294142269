import React from "react";

import Main from "components/Layout/main";
import ListpageContent from "components/UI/Listpage/listpageContent";

import 'styles/listpage.scss'
import StaticPageMeta from "../components/UI/staticPageMeta";


const Listpage = (props) => {
    const gtmData = {
        page: {
            title: "Category all",
            type: "Category",
        },
    };

    return (
        <>
        <StaticPageMeta pageName="Category all"/>
        <Main className="listpage" gtmData={gtmData}>
            <h1 className="typography__h1 listpage__title">Onze producten</h1>
            <ListpageContent/>
        </Main>
        </>
    );
};

export default Listpage;
