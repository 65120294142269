import React, { useEffect, useState } from "react";
import Image from "components/UI/image";

import "styles/quoteSlider.scss";

import PopupCategoryArrow from "images/svg/popupCategoryArrow.svg";

const QuoteSlider = (props) => {
    const { sliderData } = props;
    const { slides, autoplayInterval = 5, slideCount, quoteSliderImage, sliderTitle } = sliderData;

    const QuoteSliderContainer = () => {
        const [activeSlideIndex, setActiveSlideIndex] = useState(0);

        const showNextSlide = () => {
            setActiveSlideIndex(prev =>
                prev === slideCount - 1 ?
                    0 :
                    prev + 1
            );
        };

        useEffect(() => {
            if (!autoplayInterval) {
                return;
            }

            const intervalId = setInterval(() => {
                showNextSlide();
            }, autoplayInterval * 1000);

            return () => {
                clearInterval(intervalId);
            };
        }, [autoplayInterval]);

        return (
            <div className="quote-slider__container">
                <div className="quote-slider__text">
                    {
                        slides.map((slide, index) => (
                            <div role="presentation" onClick={showNextSlide} key={index}
                                 className={`quote-slider__text-wrapper${index === activeSlideIndex ? " quote-slider__text-wrapper--active" : ""}`}>
                                {slide.title && <p className="title">{slide.title}</p>}
                                {slide.subtext && <p className="author">{slide.subtext}</p>}
                            </div>
                        ))
                    }
                </div>

                <div className="quote-slider__controls">
                    <div className="quote-slider__buttons">
                        {slides.map((_, index) => (
                            <button
                                key={index} onClick={() => setActiveSlideIndex(index)}
                                className={`quote-slider__buttons-item${index === activeSlideIndex ? " quote-slider__buttons-item--active" : ""}`}
                            />
                        ))}
                    </div>
                    <div className="quote-slider__next-arrow" role="presentation" onClick={showNextSlide}>
                        <Image src={PopupCategoryArrow} alt="Next"/>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="quote-slider-container">
            <p className="quote-slider__title typography__h2">
                {sliderTitle}
            </p>
            <div className="quote-slider">
                {quoteSliderImage && (
                    <div className="quote-slider__image-container">
                        <Image image={quoteSliderImage} className="quote-slider__image"/>
                    </div>
                )}
                <QuoteSliderContainer/>
            </div>
        </div>
    );
};

export default QuoteSlider;
