import React from "react";

import Slider from "components/UI/slider";
import ProductCard from "components/UI/productCard";
import Button from "components/UI/button";

import "styles/product-slider.scss";

const ProductSlider = (props) => {
    const {
        products,
        title,
        subtitle,
        productSelectClick,
        isHomepage,
        isHideButtons,
        reference,
        breakpoints,
        ...restProps
    } = props;

    const sliderConfig = {
        spaceBetween: 10,
        slidesPerView: 1.2,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
        },
        breakpoints: breakpoints ?? {
            425: {
                slidesPerView: 1.4,
                spaceBetween: 20
            },
            500: {
                slidesPerView: 1.6
            },
            600: {
                slidesPerView: 1.8,
            },
            700: {
                slidesPerView: 2.1,
            },
            900: {
                slidesPerView: 2.5,
            },
            1024: {
                slidesPerView: 2.9,
            },
            1200: {
                slidesPerView: 3.1,
            },
            1440: {
                slidesPerView: 3.6,
            },
            1640: {
                slidesPerView: 3.9,
            },
            1840: {
                slidesPerView: 4.1,
            },
        },
        className: "swiper-container product-page__slider-dots",
    };

    if (!products) {
        return;
    }

    return (
        <div className="product-slider" ref={reference} {...restProps}>
            <div className="product-slider__title-wrapper">
                <div>
                    <p className="product-slider-title typography__h2">{title}</p>
                    <p className="product-slider__subtitle typography__p">{subtitle}</p>
                </div>
            </div>
            <div className="product-slider-content">
                <Slider config={sliderConfig}>
                    {products.map(product => (
                        <ProductCard
                            key={product.id}
                            product={product}
                            productSelectClick={productSelectClick}
                            isHomepage={isHomepage}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ProductSlider;
