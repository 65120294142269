import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import QuoteSlider from "components/UI/quoteSlider";
import RoundImageBanner from "../roundImageBanner";
import ProductSlider from "components/UI/productSlider";

import "styles/listpage.scss";

export const listpageQuery = graphql`
  query ListpageQuery {
    allContentfulSlider {
      edges {
        node {
          type
          location
          sliderTitle
          slideCount
          name
          dots
          autoplayInterval
          quoteSliderImage {
            title
            file {
              url
              contentType
            }
            gatsbyImageData(
              placeholder: NONE,
              formats: [AUTO, WEBP, AVIF],
            )
          }
          sliderButton {
            text
            type
            href
            isThin
            isArrowShow
          }
          slides {
            id
            title
            subtext
            slideImage {
              title
              file {
                url
                contentType
              }
              gatsbyImageData(
                placeholder: NONE,
                formats: [AUTO, WEBP, AVIF],
              )
            }
            imageMobile {
              title
              file {
                url
                contentType
              }
              gatsbyImageData(
                placeholder: NONE,
                formats: [AUTO, WEBP, AVIF],
              )
            }
            slideButton {
              text
              type
              href
              isThin
              isArrowShow
            }
          }
        }
      }
    }
    allContentfulContentfulPage(filter: { name: { eq: "Category Page" } }) {
      nodes {
        categoryPageSliders {
          sliderTitle
          type
        }
      }
    }
    allBigCommerceCategories {
      nodes {
        bigcommerce_id
        name
      }
    }
    allContentfulBannerUpdated {
      edges {
        node {
          id
          title
          plainText
          sortOrder
          location
          type
          image {
            title
            file {
              url
              contentType
            }
            gatsbyImageData(
              placeholder: NONE,
              formats: [AUTO, WEBP, AVIF],
            )
          }
          imageMobile {
            title
            file {
              url
              contentType
            }
            gatsbyImageData(
              placeholder: NONE,
              formats: [AUTO, WEBP, AVIF],
            )
          }
          bulletListText {
              id
              dropdownTitle
              text
              richText {
                raw
              }
          }
          link {
            text
            type
            href
            isThin
            isArrowShow
          }
        }
      }
    }
    allBigCommerceProducts {
      nodes {
        bigcommerce_id
        id
        sku
        name
        categories
        variants {
          id
          price
          option_values {
            id
            option_display_name
            label
          }
          image_url
        }
        price
        custom_fields {
          name
          value
        }
        images {
          is_thumbnail
          url_thumbnail
          url_standard
        }
        brand_id
        custom_url {
          url
        }
      }
    }
  }
`;

const ListpageContent = (props) => {
    const {
        allContentfulSlider,
        allContentfulContentfulPage,
        allBigCommerceCategories,
        allBigCommerceProducts
    } = useStaticQuery(listpageQuery);

    const sliders = allContentfulSlider.edges;
    const bottomSliderData = sliders.filter(
        (slider) =>
            slider.node.type === "banner-slider" &&
            slider.node.location === "homepage"
    );

    const RoundImageBannerData = sliders.filter(
        (slider) =>
            slider.node.type === "product" &&
            slider.node.location === "homepage"
    );

    const productSliders = allContentfulContentfulPage.nodes[0].categoryPageSliders;
    const categories = allBigCommerceCategories.nodes;
    const products = allBigCommerceProducts.nodes;

    return (
        <div className="listpage__content">
            {productSliders.map((slider, index) => {
                const sliderCategoryID = categories.find(el => el.name === slider.type)?.bigcommerce_id
                const sliderProducts = products.filter((product) => product.categories.includes(sliderCategoryID));

                return (
                    sliderProducts.length && (
                        <ProductSlider
                            isHomepage
                            isHideButtons
                            title={slider.sliderTitle}
                            products={sliderProducts}
                            key={index}
                        />
                    )
                );
            })}
            <QuoteSlider sliderData={bottomSliderData[0].node}/>
            <RoundImageBanner bannerData={RoundImageBannerData[0].node}/>
        </div>
    );
};

export default ListpageContent;
